import addresses from "./deploymentAddresses.json" assert { type: "json" };
export { addresses };
import PaymentFactory from "./abi/PaymentFactory.json" assert { type: "json" };
export { PaymentFactory };
import RelayReg from "./abi/RelayReg.json" assert { type: "json" };
export { RelayReg };
import StoreReg from "./abi/StoreReg.json" assert { type: "json" };
export { StoreReg };
import ERC20 from "./abi/ERC20.json" assert { type: "json" };
export { ERC20 };
